import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpLoaderFactory, LanguageLoader } from './translation-loader-factory';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ContactMeComponent } from './contactme/contactme.component';
import { RouterModule } from '@angular/router';
import { ApplicationRoutes } from './app.routes';
import { CookieService } from 'ngx-cookie-service';
import { WhoAreYouComponent } from './who-are-you/who-are-you.component';
import { FooterComponent } from './footer/footer.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ContactmeThankyouComponent } from './contactme-thankyou/contactme-thankyou.component';
import { RegistrationComponent } from './registration/registration.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IntercomModule } from 'ng-intercom';
import { SkCountryDropdownWithFlagComponent } from './sk-country-dropdown-with-flag/sk-country-dropdown-with-flag.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactMeComponent,
    WhoAreYouComponent,
    RegistrationComponent,
    FooterComponent,
    ThankYouComponent,
    ContactmeThankyouComponent,
    SkCountryDropdownWithFlagComponent
  ],
  imports: [
    IntercomModule.forRoot({
      appId: 'itg40p5r',
      updateOnRouterChange: true,
      /**
       * Customize horizontal padding
       */
      horizontal_padding: 20,
      /**
       * Customize vertical padding
       */
      vertical_padding: 20,
    }),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    GooglePlaceModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    BrowserModule,
    RouterModule.forRoot(ApplicationRoutes, {
      enableTracing: false, paramsInheritanceStrategy: 'always'
    }),
    DeviceDetectorModule.forRoot(),
    NgbModule
  ],
  entryComponents: [
    ContactMeComponent
  ],
  providers: [
    CookieService,
    { provide: APP_INITIALIZER, useFactory: LanguageLoader, multi: true, deps: [TranslateService, CookieService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
