import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { LocationApiAddress } from '../contactme/models/LocationApiAddress';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { RegistrationForm } from './models/RegistrationForm';
import { RegistrationService } from './services/registration-data.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { selectFirstPlaceChoiceOnTabKey, ValidateEmail } from '../utils';
import { SkCountryFactoryService } from '../sk-country-dropdown-with-flag/sk-country-factory.service';
import { SkCountry } from '../sk-country-dropdown-with-flag/sk-country';
import { CountryRequiresPostalCodeService } from './services/country-requires-postal-code.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  private _country: SkCountry;

  get country(): SkCountry {
    return this._country;
  }
  set country(country: SkCountry) {
    if (this._country && this._country.countryCode === country.countryCode) {
      return;
    }
    this._country = country;

    if (!country){
      return;
    }
    this.registrationForm.get("business.displayCountry").setValue(country);
    this.registrationForm.get("business.country").setValue(country.countryCode);
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translationService: TranslateService,
    private readonly cookieService: CookieService,
    private readonly registrationDataService: RegistrationService,
    private readonly deviceService: DeviceDetectorService,
    private readonly router: Router,
    private readonly postalCodeRequiredService: CountryRequiresPostalCodeService,
    private readonly countryFactory: SkCountryFactoryService
  ) {
  }
  ngOnInit() {
    selectFirstPlaceChoiceOnTabKey(document.getElementById('sk-companyname'), document.getElementById('sk-address'));
    selectFirstPlaceChoiceOnTabKey(document.getElementById('sk-city'), document.getElementById('sk-state'));
    selectFirstPlaceChoiceOnTabKey(document.getElementById('sk-state'));

    this.passwordRequirements = this.getPasswordErrorMessage("ERRORS.PASSWORD_REQUIREMENTS");
    this.passwordLengthError = this.getPasswordErrorMessage("ERRORS.PASSWORD_LENGTH");
    this.passwordPatternError = this.getPasswordErrorMessage("ERRORS.PASSWORD_PATTERN");
    this.passwordLowercaseError = this.getPasswordErrorMessage("ERRORS.PASSWORD_LOWERCASE");
    this.passwordUppercaseError = this.getPasswordErrorMessage("ERRORS.PASSWORD_UPPERCASE");
    this.passwordNumberError = this.getPasswordErrorMessage("ERRORS.PASSWORD_NUMBER");
    this.passwordSpecialCharactersError = this.getPasswordErrorMessage("ERRORS.PASSWORD_SPECIAL_CHARACTER");
  }


  public isMobile = this.deviceService.isMobile();
  public locationApiAddress = new LocationApiAddress();

  public options = Options;

  public showValidationErrors = false;
  public btnDisabled = false;
  public passwordToggle = false;

  public bannerError = { show: false, message: ''}

  public fullNameError = { valid: true, message: '' };
  public emailError = { valid: true, message: '' };
  public passwordRequiredError = '';
  public passwordRequirements = '';
  public passwordLengthError = '';
  public passwordPatternError = '';
  public passwordLowercaseError = '';
  public passwordUppercaseError = '';
  public passwordNumberError = '';
  public passwordSpecialCharactersError = '';
  public hasPasswordPatternErrors: boolean = true;
  public companyNameError = { valid: true, message: '' };

  public addressError = { valid: false, message: '' };
  public cityError = { valid: false, message: '' };
  public countryError = { valid: false, message: '' };
  public postalCodeError = { valid: false, message: '' };

  public phoneNumberError = { valid: true, message: '' };
  public mobilePhoneNumberError = { valid: true, message: '' };
  public roleError = { valid: true, message: '' };
  public experienceError = { valid: true, message: '' };
  public interestError = { valid: true, message: '' };
  public hearAboutUsError = { valid: true, message: '' };
  public termsAndConditionsError = { valid: true, message: '' };

  private lastCity: string;
  private lastState: string;
  private lastCountry: string;

  private saveAddress() {
    var city = this.registrationForm.get('business.city');
    var state = this.registrationForm.get('business.state');
    var country = this.registrationForm.get('business.country');

    this.lastCity = city.value;
    this.lastState = state.value;
    this.lastCountry = country.value;
  }
  public registrationForm = this.formBuilder.group({
    contact: this.formBuilder.group({
      fullName: this.formBuilder.control('', [Validators.required]),
      email: this.formBuilder.control('', [Validators.required, ValidateEmail]),
      password: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        this.passwordPatternsMatch()
      ])
    }),
    business: this.formBuilder.group({
      companyName: this.formBuilder.control('', [Validators.required]),

      address: this.formBuilder.control('', [Validators.required]),
      city: this.formBuilder.control('', [Validators.required]),
      state: this.formBuilder.control(''),
      postalCode: this.formBuilder.control(''),
      country: this.formBuilder.control('', [Validators.required]),
      displayCountry: this.formBuilder.control(''),

      phoneNumber: this.formBuilder.control('', [Validators.required]),
      mobilePhoneNumber: this.formBuilder.control(''),
      role: this.formBuilder.control('', [Validators.required]),
      employees: this.formBuilder.control(0),
      seats: this.formBuilder.control(0),
      experience: this.formBuilder.control('', [Validators.required]),
      expectedSeats: this.formBuilder.control(0),
      interest: this.formBuilder.control('', [Validators.required]),
      hearAboutUs: this.formBuilder.control('', [Validators.required])
    }),
    termsAndConditions: this.formBuilder.control('', [Validators.required])
  });

  public handleBusinessNameChange(address: Address) {
    var businessAddress = this.registrationForm.get('business.address');
    var businessCity = this.registrationForm.get('business.city');
    var businessState = this.registrationForm.get('business.state');
    var businessPostalCode = this.registrationForm.get('business.postalCode');

    var businessName = this.registrationForm.get('business.companyName');

    var streetNumber = address.address_components.find(x => x.types.find(y => y == 'street_number') != undefined);
    var street = address.address_components.find(x => x.types.find(y => y == 'route') != undefined)
    var address2 = address.address_components.find(x => x.types.find(y => y == 'subpremise') != undefined);
    var locality = address.address_components.find(x => x.types.find(y => y == 'locality') != undefined);
    var town = address.address_components.find(x => x.types.find(y => y == 'postal_town') != undefined);
    var state = address.address_components.find(x => x.types.find(y => y == 'administrative_area_level_1') != undefined);
    var postalCode = address.address_components.find(x => x.types.find(y => y == 'postal_code') != undefined);

    businessName.setValue(address.name);

    if (streetNumber && street && address2) {
      businessAddress.setValue(streetNumber.long_name + " " + street.long_name + " #" + address2.long_name);
    } else if (streetNumber && street) {
      businessAddress.setValue(streetNumber.long_name + " " + street.long_name);
    } else if (streetNumber) {
      businessAddress.setValue(streetNumber.long_name);
    } else if (street) {
      businessAddress.setValue(street.long_name);
    }
    else {
      businessAddress.setValue('');
    }

    if (locality) {
      businessCity.setValue(locality.long_name);
    } else if (town) {
      businessCity.setValue(town.long_name);
    } else {
      businessCity.setValue('');
    }

    if (state) {
      businessState.setValue(state.short_name);
    } else {
      businessState.setValue('');
    }

    if (postalCode) {
      businessPostalCode.setValue(postalCode.long_name);
    } else {
      businessPostalCode.setValue('');
    }

    this.SetCountryFromAddress(address);

    this.saveAddress();
  }

  public handleAddressChange(address: Address) {
    var businessAddress = this.registrationForm.get('business.address');
    var businessCity = this.registrationForm.get('business.city');
    var businessState = this.registrationForm.get('business.state');
    var businessPostalCode = this.registrationForm.get('business.postalCode');

    var streetNumber = address.address_components.find(x => x.types.find(y => y == 'street_number') != undefined);
    var street = address.address_components.find(x => x.types.find(y => y == 'route') != undefined)
    var address2 = address.address_components.find(x => x.types.find(y => y == 'subpremise') != undefined);
    var locality = address.address_components.find(x => x.types.find(y => y == 'locality') != undefined);
    var town = address.address_components.find(x => x.types.find(y => y == 'postal_town') != undefined);
    var state = address.address_components.find(x => x.types.find(y => y == 'administrative_area_level_1') != undefined);
    var postalCode = address.address_components.find(x => x.types.find(y => y == 'postal_code') != undefined);

    if (streetNumber && street && address2) {
        businessAddress.setValue(streetNumber.long_name + " " + street.long_name + " #" + address2.long_name);
    } else if (streetNumber && street) {
        businessAddress.setValue(streetNumber.long_name + " " + street.long_name);
    } else if (streetNumber) {
        businessAddress.setValue(streetNumber.long_name);
    } else if (street) {
        businessAddress.setValue(street.long_name);
    }
     else {
      businessAddress.setValue('');
    }

    if (locality) {
      businessCity.setValue(locality.long_name);
    } else if (town) {
      businessCity.setValue(town.long_name);
    } else {
      businessCity.setValue('');
    }

    if (state) {
      businessState.setValue(state.short_name);
    } else {
      businessState.setValue('');
    }

    if (postalCode) {
      businessPostalCode.setValue(postalCode.long_name);
    } else {
      businessPostalCode.setValue('');
    }

    this.SetCountryFromAddress(address);

    this.saveAddress();
  }

  public handleCityChange(address: Address) {
    var city = this.registrationForm.get('business.city');
    var state = this.registrationForm.get('business.state');
    var postalCode = this.registrationForm.get('business.postalCode');

    var localityComponent = address.address_components.find(x => x.types.find(y => y == 'locality') != undefined);
    var townComponent = address.address_components.find(x => x.types.find(y => y == 'postal_town') != undefined);
    if (localityComponent) {
      city.setValue(localityComponent.long_name);
    } else if (townComponent) {
      city.setValue(townComponent.long_name);
    } else {
      city.setValue('');
    }

    var stateComponent = address.address_components.find(x => x.types.find(y => y == 'administrative_area_level_1') != undefined);
    if (stateComponent) {
      state.setValue(stateComponent.short_name);
    } else {
      state.setValue('');
    }
    var postalCodeComponent = address.address_components.find(x => x.types.find(y => y == 'postal_code') != undefined);
    if (postalCodeComponent) {
      postalCode.setValue(postalCodeComponent.long_name);
    } else if (city.value !== this.lastCity) {
      postalCode.setValue('');
    }
    this.SetCountryFromAddress(address);

    this.saveAddress();
  }

  public handleStateChange(address: Address) {
    var state = this.registrationForm.get('business.state');
    var postalCode = this.registrationForm.get('business.postalCode');

    var stateComponent = address.address_components.find(x => x.types.find(y => y == 'administrative_area_level_1') != undefined);
    if (stateComponent) {
      state.setValue(stateComponent.short_name);
    } else {
      state.setValue('');
    }

    var postalCodeComponent = address.address_components.find(x => x.types.find(y => y == 'postal_code') != undefined);
    if (postalCodeComponent) {
      postalCode.setValue(postalCodeComponent.long_name);
    } else if (state.value !== this.lastState) {
      postalCode.setValue('');
    }

    this.SetCountryFromAddress(address);

    this.saveAddress();
  }

  public handleCountryChange(address: Address) {
    var postalCode = this.registrationForm.get('business.postalCode');
    var country = this.registrationForm.get('business.country');

    this.SetCountryFromAddress(address);

    var postalCodeComponent = address.address_components.find(x => x.types.find(y => y == 'postal_code') != undefined);
    if (postalCodeComponent) {
      postalCode.setValue(postalCodeComponent.long_name);
    } else if (country.value !== this.lastCountry) {
      postalCode.setValue('');
    }

    this.saveAddress();
  }

  public TermsAndConditionsSelected(): { valid: boolean, message: string } {
    var control = this.registrationForm.get('termsAndConditions');

    if (control.value)
    {
      return { valid: true, message: '' };
    }

    return { valid: false, message: '' };
  }

  public SetCountryFromAddress(address: Address) {
    const addressCountry = address.address_components.find(c => c.types.find(t => t == 'country') != undefined);
    if (addressCountry) {
      this.country = this.countryFactory.createCountry(`${addressCountry.long_name} (${addressCountry.short_name})`);
    } else {
      this.country = this.countryFactory.createCountry();
    }
  }

  public SetCountry(value: SkCountry) {
    this.country = value;
  }

  public SetRole(value: number) {
    var control = this.registrationForm.get("business.role");
    control.setValue(value);
  }

  public SetExperience(value: number) {
    var control = this.registrationForm.get("business.experience");
    control.setValue(value);
  }

  public SetInterest(value: number) {
    var control = this.registrationForm.get("business.interest");
    control.setValue(value);
  }

  public SetHearAboutUs(value: number) {
    var control = this.registrationForm.get("business.hearAboutUs");
    control.setValue(value);
  }

  public RoleIsActive(roleNumber: number) : boolean {
    var role = this.registrationForm.value.business.role;
    if(role === ''){
      return false;
    } else {
      return roleNumber === role;
    }
  }

  public ExperienceIsActive(experienceNumber: number) : boolean {
    var experience = this.registrationForm.value.business.experience;
    if(experience === ''){
      return false;
    } else {
      return experienceNumber === experience;
    }
  }

  onSubmit()
  {
    this.btnDisabled = true;

    const registrationFormData: RegistrationForm = this.registrationForm.getRawValue();
    // fix display value, because it is now a Country object
    registrationFormData.business.displayCountry = this.country.displayName;

    if (this.registrationForm.valid) {
      this.showValidationErrors = false;
      this.bannerError.show = false;

      try {
        var request = this.registrationDataService.ConvertRegistrationForm(registrationFormData);
        this.registrationDataService.register(request).subscribe(() => {
          this.router.navigate(['thank-you']);
        },(error) => {
          console.error(error);
          this.btnDisabled = false;
          this.setEverythingValid();
          this.showValidationErrors = true;

          if (error.error.message === "CONTACT_EXISTS" || error.error.InnerException.Message === "CONTACT_EXISTS") {
            this.bannerError = {
              show: true,
              message: this.translationService.instant(
                "ERRORS.EMAIL_EXISTS", {
                  loginUrl: `https://${environment.prefix}portal.skykick.com`,
                  supportUrl: "https://www.skykick.com/support/get-support/"
                })
            };
          }
          else {
            this.bannerError = { show: true, message: this.translationService.instant("ERRORS.PLATFORM_ERROR")};
          }
        });
      } catch (ex) {
        console.error(ex);
        this.btnDisabled = false;
        this.bannerError = { show: true, message: this.translationService.instant("ERRORS.PLATFORM_ERROR")};
      }
    }
    else {
      this.showValidationErrors = true;

      this.fullNameError = this.IsSimpleTextFieldValid("contact.fullName", "ERRORS.NAME_REQUIRED");
      this.emailError = this.EmailIsValid();
      this.passwordRequiredError = this.getPasswordErrorMessage("ERRORS.PASSWORD_REQUIRED");
      this.companyNameError = this.IsSimpleTextFieldValid("business.companyName", "ERRORS.BUSINESS_NAME_REQUIRED");

      this.addressError = this.IsSimpleTextFieldValid("business.address", "ERRORS.ADDRESS_REQUIRED");
      this.cityError = this.IsSimpleTextFieldValid("business.city", "ERRORS.CITY_REQUIRED");
      this.countryError = this.IsSimpleTextFieldValid("business.country", "ERRORS.COUNTRY_REQUIRED");
      var country = this.registrationForm.value.business.country;
      if (this.postalCodeRequiredService.isPostalCodeRequired(country)) {
        this.postalCodeError = this.IsSimpleTextFieldValid("business.postalCode", "ERRORS.POSTAL_CODE_REQUIRED");
      } else {
        this.postalCodeError = { valid: true, message: '' };
      }

      this.phoneNumberError = this.IsSimpleTextFieldValid("business.phoneNumber", "ERRORS.PHONE_REQUIRED");
      this.roleError = this.RoleIsValid();
      this.experienceError = this.ExperienceIsValid();
      this.interestError = this.InterestIsValid();
      this.hearAboutUsError = this.HearAboutUsIsValid();
      this.termsAndConditionsError = this.TermsAndConditionsSelected();
      this.btnDisabled = false;
    }
  }

  public setEverythingValid() {
    this.fullNameError = { valid: true, message: '' };
    this.emailError = { valid: true, message: '' };
    this.companyNameError = { valid: true, message: '' };

    this.addressError = { valid: true, message: '' };
    this.cityError = { valid: true, message: '' };
    this.countryError = { valid: true, message: '' };
    this.postalCodeError = {valid: true, message: ''};

    this.phoneNumberError = { valid: true, message: '' };
    this.roleError = { valid: true, message: '' };
    this.experienceError = { valid: true, message: '' };
    this.interestError = { valid: true, message: '' };
    this.hearAboutUsError = { valid: true, message: '' };
    this.termsAndConditionsError = { valid: true, message: '' };
  }

  public RoleIsValid(): { valid: boolean, message: string } {
    if (this.registrationForm.value.business.role !== '' || this.registrationForm.value.business.role === 0) {
      return { valid: true, message: '' };
    }
    return { valid: false, message: this.translationService.instant("ERRORS.ROLE_REQUIRED") };
  }

  public ExperienceIsValid(): { valid: boolean, message: string } {
    if (this.registrationForm.value.business.experience !== '' || this.registrationForm.value.business.experience === 0) {
      return { valid: true, message: '' };
    }
    return { valid: false, message: this.translationService.instant("ERRORS.EXPERIENCE_REQUIRED") };
  }

  public InterestIsValid(): { valid: boolean, message: string } {
    try {
      if (!this.registrationForm.value.business.interest) {
        if (!(this.registrationForm.value.business.interest === 0)){
          return { valid: false, message: this.translationService.instant("ERRORS.INTEREST_REQUIRED") };
        }
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  public HearAboutUsIsValid(): { valid: boolean, message: string } {
    try {
      if (!this.registrationForm.value.business.hearAboutUs) {
        if (!(this.registrationForm.value.business.hearAboutUs === 0)){
          return { valid: false, message: this.translationService.instant("ERRORS.HEAR_ABOUT_US_REQUIRED") };
        }
      }
    }
    catch
    {

    }
    return { valid: true, message: '' };
  }

  public EmailIsValid(): { valid: boolean, message: string } {
    var control = this.registrationForm.get('contact.email');
    var emailValidation = ValidateEmail(control);
    var requiredValidation = Validators.required(control);
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant("ERRORS.EMAIL_REQUIRED") };
      }
    }
    catch
    {

    }
    try { if (emailValidation.InvalidEmail) {
      return { valid: false, message: this.translationService.instant("ERRORS.EMAIL_NOT_VALID") };
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  public SetPostalCodeValidator():void {
    var country = this.registrationForm.value.business.country;

      const postalCodeControl = this.registrationForm.get('business.postalCode');
    if (this.postalCodeRequiredService.isPostalCodeRequired(country)) {
      postalCodeControl.setValidators([Validators.required]) ;
    } else {
      postalCodeControl.setValidators(null) ;
    }
    postalCodeControl.updateValueAndValidity();
  }

  public IsSimpleTextFieldValid(fieldName, errorMessage): { valid: boolean, message: string } {
    var control = this.registrationForm.get(fieldName);
    var requiredValidation = Validators.required(control);
    try {
      if (requiredValidation.required) {
        return { valid: false, message: this.translationService.instant(errorMessage) };
      }
    }
    catch
    {

    }
    return { valid: true, message: ""};
  }

  changeLanguage(languageCode: string) {
    var expirationDate = new Date();
    this.cookieService.set('skLanguageCode', languageCode, new Date(expirationDate.setFullYear(expirationDate.getFullYear() + 10)), "/", null);
    window.location.reload();
  }

  closePlatformError() {
    this.bannerError.show = false;
  }

  passwordPatternsMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const passwordPatterns: [string, RegExp][] = [
        ['hasNumber', /\d/],
        ['hasLowerCase', /[a-z]/],
        ['hasUpperCase', /[A-Z]/],
        ['hasSpecialCharacters', /[*!@#$%^&()\[\]{}:";'<>,.?\/~`_+=|\\]/]
      ];

      const errors: ValidationErrors = {};
      passwordPatterns.forEach(pattern => {
        const valid = pattern[1].test(control.value);
        if (!valid){
          errors[pattern[0]] = true;
        }
      });

      if (Object.keys(errors).length > 1) {
        this.hasPasswordPatternErrors = true;
        return errors;
      }

      this.hasPasswordPatternErrors = false;
      return null;
    };
  }

  getPasswordErrorMessage(key: string): string {
    return this.translationService.instant(key);
  }

  passwordIsValid(): boolean {
    var errors = this.registrationForm.controls.contact.get('password').errors;
    return errors == null;
  }

  isPasswordRuleValid(ruleName: string): boolean {
    const password = this.registrationForm.controls.contact.get('password');
    return password.value && !password.hasError(ruleName);
  }
}

const Options = {
  Employees: ['EMPLOYEERANGE.ONE', 'EMPLOYEERANGE.TWO', 'EMPLOYEERANGE.THREE', 'EMPLOYEERANGE.FOUR', 'EMPLOYEERANGE.FIVE'],
  Seats: ['SEATRANGE.ONE', 'SEATRANGE.TWO', 'SEATRANGE.THREE', 'SEATRANGE.FOUR', 'SEATRANGE.FIVE'],
  Roles: ['ROLES.ONE', 'ROLES.TWO', 'ROLES.THREE', 'ROLES.FOUR', 'ROLES.FIVE', 'ROLES.SIX'],
  Experience: ['EXPERIENCE.ONE', 'EXPERIENCE.TWO', 'EXPERIENCE.THREE', 'EXPERIENCE.FOUR'],
  ExpectedSeats: ['EXPECTEDSEATSRANGE.ONE', 'EXPECTEDSEATSRANGE.TWO', 'EXPECTEDSEATSRANGE.THREE', 'EXPECTEDSEATSRANGE.FOUR', 'EXPECTEDSEATSRANGE.FIVE'],
  Interest: ['INTEREST.ONE', 'INTEREST.TWO', 'INTEREST.THREE', 'INTEREST.FOUR', 'INTEREST.FIVE', 'INTEREST.SIX', 'INTEREST.SEVEN', 'INTEREST.EIGHT'],
  HearAboutUs: ['HEARABOUTUS.ONE', 'HEARABOUTUS.TWO', 'HEARABOUTUS.THREE', 'HEARABOUTUS.FOUR', 'HEARABOUTUS.FIVE', 'HEARABOUTUS.SIX', 'HEARABOUTUS.SEVEN'],
  Api: {
    types: ['establishment']
  },
  AddressApi: {
    types: ['address']
  },
  CityApi: {
    types: ['(cities)']
  },
  CountryApi: {
    types: ['country']
  },
  StateApi: {
    types: ['administrative_area_level_1']
  }
}
