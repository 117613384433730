<div class="sk-reg-footer mt-5">
  <div class="container sk-reg">
    <div class="row">
      <div class="col-xs-12 col-md-12 p-0 text-center">
        <div class="btn-group dropup" ngbDropdown>
          <button type="button" class="btn btn-basic dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="true" id="sk-registerPartnerLanguage" translate="COMMON.LANGUAGE" ngbDropdownToggle></button>
          <div class="dropdown-menu" x-placement="bottom-start" ngbDropdownMenu>
            <a class="dropdown-item" ngbDropdownItem translate="COMMON.GERMAN" (click)="changeLanguage('de');"
              href="#"></a>
            <a class="dropdown-item" ngbDropdownItem translate="COMMON.ENGLISH" (click)="changeLanguage('en');"
              href="#"></a>
            <a class="dropdown-item" ngbDropdownItem translate="COMMON.JAPANESE" (click)="changeLanguage('ja');"
              href="#"></a>
            <a [hidden]="environmentPrefix === ''" class="dropdown-item" ngbDropdownItem translate="COMMON.PSEUDO" (click)="changeLanguage('sq');"
              href="#"></a>
          </div>
        </div>

        <span class="p-r-1 ng-binding pr-100 d-xs-none d-sm-none d-lg-inline d-md-inline d-xl-inline"
          [innerHTML]="'COMMON.FOOTER' | translate:{ year: currentYear}"></span>
        <span class="p-r-1 pr-100" [innerHTML]="'COMMON.TERMS' | translate:{ url: 'https://www.skykick.com/partner-terms-conditions/' }"></span>
        <span class="p-r-1 pr-100" [innerHTML]="'COMMON.PRIVACY_POLICY' | translate:{ url: 'https://www.skykick.com/privacy-policy' }"></span>
      </div>
      <div class="col-xs-12 col-md-8 text-center d-lg-none d-md-none d-xl-none">
        <span class="p-r-1 ng-binding pr-100" [innerHTML]="'COMMON.FOOTER' | translate:{ year: currentYear }"></span>
      </div>
    </div>
  </div>
</div>