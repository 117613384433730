<nav class="navbar fixed-top sk-header sk-reg">
  <div class="container sk-reg">
    <div class="sk-logo sk-reg p-0">
      <a href="https://www.skykick.com/" id="sk-registerNoGenPageAttributePartnerLogo">
        <img id="logo" src="../assets/images/skykick-logo.svg">
      </a>
    </div>
  </div>
</nav>
<div class="container sk-content sk-reg my-10">
  <h1 class="display-3 text-center my-5"></h1>
<div class="container sk-reg card sk-card">
  <div class="row">
    <div class="col-sm-12 text-center my-5">
      <img class="pb-100"  src="../assets/images/art-reg-success.svg">
      <h3 class="mt-2" translate="THANK_YOU.TITLE"></h3>
    </div>
  </div>
  <div class="row mb-5 justify-content-center">
    <div class="col-md-3">
      <a class="btn btn-md btn-primary btn-block" id="loginLink" (click)="submit()" type="submit" translate="THANK_YOU.LOG_IN"></a>
    </div>
  </div>
</div>
<app-footer></app-footer>
</div>
