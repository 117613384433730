import * as i0 from "@angular/core";
export class CountryRequiresPostalCodeService {
    constructor() {
        this.countriesRequiringPostalCode = ['US', 'GB', 'DE', 'AU'];
    }
    isPostalCodeRequired(countryCode) {
        return this.countriesRequiringPostalCode.some(cc => cc === countryCode);
    }
}
CountryRequiresPostalCodeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CountryRequiresPostalCodeService_Factory() { return new CountryRequiresPostalCodeService(); }, token: CountryRequiresPostalCodeService, providedIn: "root" });
