import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryRequiresPostalCodeService {
  private countriesRequiringPostalCode: ReadonlyArray<string> = ['US', 'GB', 'DE', 'AU'];
  constructor() { }
  public isPostalCodeRequired(countryCode: string):boolean{
    return this.countriesRequiringPostalCode.some(cc => cc === countryCode);
  }
}
